import './App.scss';
import HeaderComponent from './components/HeaderComponent';
import ContactComponent from './components/ContactComponent';
import PresentationComponent from './components/PresentationComponent';
import DetailsComponent from './components/DetailsComponents';
import FooterComponent from './components/FooterComponent';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';



function App() {
  return (
    <div className="App">
      <HeaderComponent/>
      <PresentationComponent/>
      <DetailsComponent/>
      <ContactComponent/>
      <FooterComponent/>
    </div>
  );
}

export default App;
