import React, {useRef} from "react";
import emailjs from '@emailjs/browser';
import '../styles/ContactComponent.scss';


function ContactComponent() {


    const form = useRef();



    const sendForm = (e) => {

       
        e.preventDefault();

        emailjs.sendForm('service_zc1xn1p', 'template_893t7ox', form.current, '56RusQ7X2paXUcj4O')
            .then( (result) => {
                console.log(result.text);
                alert("Your message has been sent !");
            }, (error) => {
                alert("An error happend. Please try later or send an email to : info@pierrehydraulic.com");
                console.log(error.text);
            });

    }



    return (
        <>
            <h2 className="contact-title">CONTACT US ! </h2>

            <div className="contact-form-container">

                <form ref={form} onSubmit={sendForm}>

                    <div className="form-infos">
                        <div className="contact-form-question">
                            <label htmlFor="subject">Subject</label>
                            <input type="text" name="subject" id="subject" v-model="subject" placeholder="Subject"/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" name="firstName" id="firstName" v-model="firstName" placeholder="First Name *"
                            required/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" name="lastName" id="lastName" v-model="lastName" placeholder="Last Name *"
                            required/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="address">Address</label>
                            <input type="text" name="address" id="address" v-model="address" placeholder="Address"/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="city">City</label>
                            <input type="text" name="city" id="city" v-model="city" placeholder="City *" required/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="phone">Phone Number</label>
                            <input type="text" name="phone" id="phone" v-model="phone" placeholder="Phone number"/>
                        </div>
                        <div className="contact-form-question">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" v-model="email" placeholder="Email *" required/>
                        </div>
                    </div>  

                    <div className="form-request">
                        <div className="contact-form-question">
                            <label htmlFor="text">Your request</label>
                            <textarea name="text" id="text" v-model="text" rows="5" cols="33" placeholder="Your request *"
                            required></textarea>
                        </div>
                    </div>

                    <div className="contact-form-submit">
                        <input type="submit" value="Send !" id="send"/>
                    </div>
                </form>

            </div >

    <div className="required-field">
        <p>* : required field</p>
    </div>

    <div className="call-us">
        <h4>Or call us directly :</h4>
        <h4>+44 739 516 3728</h4>
    </div>


    </>
    );
}

export default ContactComponent;