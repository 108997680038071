import React from 'react';
import '../styles/PresentationComponent.scss'
import pres1 from '../assets/pres1.jpg';

function PresentationComponent() {
  return (
    <section className="prez">
    <div className="prez-text">
        <h2>Pierre Hydraulic Services, your new local maintenance provider</h2>

        <div className="prez-paragraph">
            <p>Hydraulic systems are widely utilized across various industries, ranging from construction to aerospace.
                <br />These systems rely on fluid power to generate force, making them ideal for heavy-duty
                applications.
                <br />Hydraulic systems are known for their durability and reliability, making them a popular choice for
                critical operations.
            </p>
        </div>
        <div className="prez-paragraph">
            <p>Our work process is carefully designed to ensure efficiency and quality.
                We begin by thoroughly understanding the client's needs and requirements.
                This is followed by research and analysis to identify the best approach.
                <br />Our team then collaborates to develop a detailed plan of action, which is reviewed and approved by
                the
                client.
                We work diligently to execute the plan, closely monitoring progress and making adjustments as necessary.
                Throughout the process, we maintain open communication with the client to ensure their satisfaction.
                Our commitment to excellence and attention to detail is reflected in the high-quality results we
                consistently deliver.
                We are trained and experienced since 1998 to repair / service any types of industrial systems and Ground
                Support Equipment.

                Whether you are large or small production machinery, mobile equipment, or aircraft industry.
            </p>
        </div>
    </div>
    <div className="prez-img">
    <img src={pres1} alt="Pierre in front of a big hydraulic machine"/>

    </div>


</section>
 
  );
}

export default PresentationComponent;
