import React from 'react';
import '../styles/DetailsComponent.scss'
import CarouselComponent from './CarouselComponent';

function DetailsComponent() {
    return (
        <section className='details-component'>
        <div className="details">

            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#preventiveMaintenance" aria-expanded="false" aria-controls="preventiveMainteance">
                    Preventive Maintenance </button>
            </p>
            <div>
                <div className="collapse" id="preventiveMaintenance">
                    <div className="card card-body">
                        Preventive maintenance is an essential practice for ensuring the longevity and efficiency of any machinery
                        or equipment.
                        By scheduling regular maintenance, potential issues can be identified and addressed before they become major
                        problems.
                        This not only saves time and money in the long run, but also increases safety and reduces the risk of
                        downtime.
                        Additionally, preventive maintenance can improve the overall performance and reliability of the machinery or
                        equipment, leading to increased productivity and profitability.
                        In short, the benefits of preventive maintenance cannot be overstated and it is highly recommended for any
                        organization that relies on machinery or equipment.                    </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#EquipmentInpection" aria-expanded="false" aria-controls="EquipmentInpection">
                    Inspection of equipment </button>
            </p>
            <div>
                <div className="collapse" id="EquipmentInpection">
                    <div className="card card-body">
                        The annual inspection of our equipment may be a crucial part of our safety protocol.
                        It ensures that all machinery or equipment is in proper working order and meets industry standards.
                        Our team thoroughly examines each piece of equipment and provides certification upon passing inspection.
                        This process guarantees the safety of our employees and the quality of our work.                    </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#Troubleshooting" aria-expanded="false" aria-controls="Troubleshooting">
                    Troubleshooting </button>
            </p>
            <div>
                <div className="collapse" id="Troubleshooting">
                    <div className="card card-body">
                        Having strong troubleshooting skills is essential in our field.
                        It involves the ability to identify and diagnose problems, develop solutions, and implement them
                        effectively.
                        Troubleshooting skills require a combination of technical knowledge, critical thinking, and problem-solving
                        abilities.
                        Being able to troubleshoot efficiently can save time, money, and resources.
                        It is a valuable skill that can benefit your organizations.                   </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#repair" aria-expanded="false" aria-controls="repair">
                   Repair</button>
            </p>
            <div>
                <div className="collapse" id="repair">
                    <div className="card card-body">
                    Our team possess a range of repair skills that I have developed over the years.
                    These skills include but are not limited to: hydraulic, pneumatic, and mechanical repair.
                    Our team have gained experience through hands-on learning, and acquiring qualifications. PHS has
                    successfully completed various repair projects.                 </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#pneumaticMechanical" aria-expanded="false" aria-controls="pneumaticMechanical">
                    Pneumatic and Mechanical</button>
            </p>
            <div>
                <div className="collapse" id="pneumaticMechanical">
                    <div className="card card-body">
                    We are pleased to inform you that in addition to our other services, we also offer pneumatic and mechanical
                    options.
                    Our team is highly skilled and experienced in these areas, and we are confident that we can provide you with
                    the best possible solutions for your needs.
                    Please do not hesitate to contact us to discuss your specific requirements, and we will be more than happy
                    to assist you.                 </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#sparesParts" aria-expanded="false" aria-controls="sparesParts">
                   Spares Parts </button>
            </p>
            <div>
                <div className="collapse" id="sparesParts">
                    <div className="card card-body">
                    PHS works closely with the main manufacturers to ensure that our products meet the highest standards of
                    quality and efficiency.
                    Our team will always give you the best-selling price and fastest lead time.
                    Stock available Feel free to enquire for a quotation.                  </div>
                </div>
            </div>
            <p>
                <button className="details-title" type="button" data-bs-toggle="collapse" data-bs-target="#iso" aria-expanded="false" aria-controls="iso">
                    ISO </button>
            </p>
            <div>
                <div className="collapse" id="iso">
                    <div className="card card-body">
                    PHS has decided to be ISO 9001 and 14001 registered.
                    This is to demonstrate how important it is to us to work with a good frame / procedures. And mainly
                    constantly monitoring that what we do it's what we say. To deliver the best service and parts to our
                    customers.
                    The people and the environment are back bone of PHS.                  </div>
                </div>
            </div>
            {/* <div className="details-item">
                <h3 className="details-title">Preventive maintenance</h3>
                <div className='details-infos'>
                    <p className="details-text">
                        Preventive maintenance is an essential practice for ensuring the longevity and efficiency of any machinery
                        or equipment.
                        By scheduling regular maintenance, potential issues can be identified and addressed before they become major
                        problems.
                        This not only saves time and money in the long run, but also increases safety and reduces the risk of
                        downtime.
                        Additionally, preventive maintenance can improve the overall performance and reliability of the machinery or
                        equipment, leading to increased productivity and profitability.
                        In short, the benefits of preventive maintenance cannot be overstated and it is highly recommended for any
                        organization that relies on machinery or equipment.
                    </p>
                </div>
            </div> */}

            {/* <div className="details-item">
                <h3 className="details-title">Inspection of equipment</h3>
                <p className="details-text">
                    The annual inspection of our equipment may be a crucial part of our safety protocol.
                    It ensures that all machinery or equipment is in proper working order and meets industry standards.
                    Our team thoroughly examines each piece of equipment and provides certification upon passing inspection.
                    This process guarantees the safety of our employees and the quality of our work.
                </p>
            </div> */}

            {/* <div className="details-item">
                <h3 className="details-title">Troubleshooting / call out</h3>
                <p className="details-text">
                    Having strong troubleshooting skills is essential in our field.
                    It involves the ability to identify and diagnose problems, develop solutions, and implement them
                    effectively.
                    Troubleshooting skills require a combination of technical knowledge, critical thinking, and problem-solving
                    abilities.
                    Being able to troubleshoot efficiently can save time, money, and resources.
                    It is a valuable skill that can benefit your organizations.
                </p>
            </div> */}

            {/* <div className="details-item">
                <h3 className="details-title">Repair</h3>
                <p className="details-text">
                    Our team possess a range of repair skills that I have developed over the years.
                    These skills include but are not limited to: hydraulic, pneumatic, and mechanical repair.
                    Our team have gained experience through hands-on learning, and acquiring qualifications. PHS has
                    successfully completed various repair projects.
                </p>
            </div> */}

            {/* <div className="details-item">
                <h3 className="details-title">Pneumatic and Mechanical</h3>
                <p className="details-text">
                    We are pleased to inform you that in addition to our other services, we also offer pneumatic and mechanical
                    options.
                    Our team is highly skilled and experienced in these areas, and we are confident that we can provide you with
                    the best possible solutions for your needs.
                    Please do not hesitate to contact us to discuss your specific requirements, and we will be more than happy
                    to assist you.
                </p>

            </div> */}

            {/* <div className="details-item">
                <h3 className="details-title">Spares parts</h3>
                <p className="details-text">
                    PHS works closely with the main manufacturers to ensure that our products meet the highest standards of
                    quality and efficiency.
                    Our team will always give you the best-selling price and fastest lead time.
                    Stock available Feel free to enquire for a quotation.
                </p>
            </div>

            <div className="details-item">
                <h3 className="details-title">ISO</h3>
                <p className="details-text">
                    PHS has decided to be ISO 9001 and 14001 registered.
                    This is to demonstrate how important it is to us to work with a good frame / procedures. And mainly
                    constantly monitoring that what we do it's what we say. To deliver the best service and parts to our
                    customers.
                    The people and the environment are back bone of PHS.
                </p>
            </div> */}

        </div>
        <div className='carousel-component'>
        <CarouselComponent/>
        </div>

        </section>
    );
}

export default DetailsComponent;



