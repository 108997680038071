import React from 'react';
import '../styles/FooterComponent.scss'
import iso from '../assets/ISO-9001-14001-IMS-badge-white.jpg';

function FooterComponent() {
  return (
    <footer>

    <div className="footer-nav">

      <div className="certification">
        <h4>ISO certification</h4>
        <img src={iso} alt="certification iso" />
      </div>

     

      <div className="contacts">
        <h4>Contact</h4>
        <a href="mailto:info@hydraulic-services.co.uk">info@pierrehydraulic.com</a>
        <div className="mobile">
          <font-awesome-icon icon="fa-solid fa-mobile-screen-button" />
          <p>+44 739 516 3728</p>
        </div>
        <p className="location">West London</p>
      </div>

      <div className="hours">
        <h4>Buisness hours</h4>
        <p>Mon - Friday : 8 am to 5 pm</p>
        <p>Weekend : Booked only</p>
      </div>

    </div>

    <div className="legal-mentions">
      <p className>Copyright © 2023 PHS</p>
    </div>


  </footer>
  );
}

export default FooterComponent;
