
import React from 'react';
import carousel1 from '../assets/phs_carousel_1.jpg';
import carousel2 from '../assets/phs_carousel_2.jpg';
import carousel3 from '../assets/phs_carousel_3.jpg';
import carousel4 from '../assets/phs_carousel_4.jpg';
import carousel5 from '../assets/phs_carousel_5.jpg';
import carousel6 from '../assets/phs_carousel_6.jpg';
import carousel7 from '../assets/phs_carousel_7.jpg';
import carousel8 from '../assets/phs_carousel_8.jpg';
import carousel9 from '../assets/phs_carousel_9.jpg';
import carousel10 from '../assets/phs_carousel_10.jpg';
import carousel11 from '../assets/phs_carousel_11.jpg';

function CarouselComponent() {
  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="1000">
          <img src={carousel1} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel2} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel3} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel4} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel5} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel6} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel7} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel8} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel9} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel10} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={carousel11} className="d-block w-100" alt="..." />
        </div>
      </div>
    </div>
  );
}

export default CarouselComponent;





