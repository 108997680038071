import React from 'react';
import '../styles/HeaderComponent.scss'
import logo from '../assets/PHS-Logo-Colour-BG.jpg';

function HeaderComponent() {
  return (
    <header className='header'>
        <img src={logo} alt="logo"/>

        <p className="title">Pierre Hydraulic Services</p>
    </header>
  );
}

export default HeaderComponent;
